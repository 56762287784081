
html h2, html h3 {
    padding-top: 20px !important;
}

.headingAnchor {
    text-decoration: none !important;
    padding-bottom: 5px;
    position: relative;
}

*:hover > .headingAnchor:before {
    content: '🔗';
    font-size: 14px;
    position: absolute;
    right: -15px;
    top: 30%;
    margin-top: -5px;
}

.homeWrapper {

    padding: 0;

    .videoBar {
        display: flex;
        justify-content: center;
        flex-direction: column;
        align-items: center;
        padding: 20px 0;
        @include media-query($on-palm) {
            padding: 0;
        }

        video {
            max-width: 700px;
            @include media-query($on-palm) {
                max-width: 100%;
            }
        }
    }
}

.homeBanner {

    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    padding: 40px 0;
    background: linear-gradient(217deg, rgba(82, 147, 255, 1), rgba(161, 68, 255, 1));
    color: white;

    h1 {
        margin: 10px 0 0;
    }

    span {
        margin: 0 0 20px;
    }
}

a.download {
    position: relative;
    background: url(/assets/images/downloadAppStore.svg) center center;
    background-size: cover;
    width: 180px;
    height: 60px;
    box-sizing:border-box;
    margin: auto;
    display: block;
    margin-top: 20px;
}

.titleWrapper {
    display: flex;
    justify-content: center;
}

.site-title {
    display: flex;
    align-items: center;
    justify-content: center;
}


.icon {

    &.main {
        background: url(/assets/images/icon-new.png) center center;
        background-size: cover;
        margin: 40px 20px 40px 0;
        width: 80px;
        height: 80px;
        float: left;
    }

    position: relative;
    border-bottom-color:rgb(51, 51, 51);
    border-bottom-left-radius:22.5%;
    border-bottom-right-radius:22.5%;
    border-top-left-radius:22.5%;
    border-top-right-radius:22.5%;
    box-sizing:border-box;
    -webkit-font-smoothing:antialiased;
    -webkit-mask-clip:border-box;
    -webkit-mask-origin:border-box;
    -webkit-mask-position-x:50%;
    -webkit-mask-position-y:50%;
    -webkit-mask-size:100% 100%;
}